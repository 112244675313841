<template>
  <div v-if="room">
    <h1 :class="$style.header">Kamer code: {{ room.roomCode }}</h1>
    <div :class="$style.players">
      <h2>Spelers</h2>
      <ul :class="$style.list">
        <li
          :class="$style.item"
          v-for="player in room.clients"
          :key="player.id"
        >
          {{ getUserName(player.id) }}
          <span v-if="player.ready">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
            >
              <path
                d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
              />
            </svg>
          </span>
          <span v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
            >
              <path
                d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"
              />
            </svg>
          </span>
        </li>
      </ul>
    </div>
    <button :class="$style.ready" @click="ready">Gereed</button>
    <label v-if="error">{{ error }}</label>
  </div>
  <div v-else>
    <h1 :class="$style.header">Creëer een kamer</h1>
    <base-button :label="'Creëer'" @click.native="create" />
    <h1 :class="$style.header">Join een kamer</h1>
    <input v-model.lazy="roomCode" :class="$style.input" />
    <base-button :label="'Join'" @click.native="join" />
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue';

export default {
  name: 'BaseRoom',
  components: {
    BaseButton,
  },
  data() {
    return {
      room: null,
      error: null,
      roomCode: null,
      start: false,
    };
  },
  sockets: {
    joined(room) {
      this.room = room;
      this.$emit('joinedRoom', room);
    },
    roomStatus(room) {
      this.room = room;
    },
    start() {
      this.$emit('startGame');
    },
    error(message) {
      this.error = message;
    },
  },
  methods: {
    getUserName(id) {
      let colleague = this.$store.getters.getColleagueById(id);
      if (colleague) {
        return `${colleague.firstname} ${colleague.middlename} ${colleague.lastname}`;
      } else {
        return 'Gebruiker';
      }
    },
    create() {
      this.$socket.client.emit('create', this.$store.state.user.id);
    },
    join() {
      this.$socket.client.emit(
        'join',
        this.roomCode,
        this.$store.state.user.id
      );
    },
    ready() {
      this.$socket.client.emit('ready', this.room, this.$store.state.user.id);
    },
  },
};
</script>

<style lang="scss" module>
.input {
  background: $bg;
  color: $primary;
  padding: $spacing-small $spacing-default;
  font-size: 1rem;
  border: $primary solid 2px;
  border-right: 0;
  font-family: $font-family;
  &:focus {
    border-radius: 0;
  }
}

.players {
  border-top: 2px $primary solid;

  h2 {
    margin: $spacing-default;
  }
  .list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 2px $primary solid;
      padding: $spacing-small 0;

      &:first-child {
        border-top: 2px $primary solid;
      }

      span {
        display: flex;
      }

      svg {
        margin-left: $spacing-default;
      }
    }

    path {
      fill: $primary;
    }
  }
}

.ready {
  margin-top: $spacing-large;
}
.header {
  font-size: 1.5rem;
}
</style>
