<template>
  <div :class="$style.window">
    <div v-if="open">
      <transition-group tag="ul" name="fade" id="chat-list">
        <li v-for="(message, index) in messages" :key="index + 0">
          {{ message }}
        </li>
      </transition-group>
    </div>
    <div :class="$style.chatbox">
      <button :class="$style.chevron" @click="toggleChat">
        <svg
          v-if="open"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"
          />
        </svg>
      </button>
      <input v-model="value" @keyup.enter="send" />
      <button @click="send">Verstuur</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseChatBox',
  props: {
    messages: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      value: '',
      open: true,
    };
  },
  methods: {
    send() {
      this.$emit('sendMessage', this.value);
      this.value = '';
      this.open = true;
    },
    toggleChat() {
      this.open = !this.open;
    },
  },
};
</script>

<style lang="scss" module>
.window {
  display: flex;
  flex-direction: column;

  .chatbox {
    display: grid;
    grid-template-columns: 50px minmax(100px, 400px) auto;

    .chevron {
      padding: $spacing-small;
      path {
        fill: $primary;
      }
    }

    input {
      background: $bg;
      color: $primary;
      padding: $spacing-small $spacing-default;
      font-size: 1rem;
      border: $primary solid 2px;
      border-right: 0;
      border-left: 0;
      font-family: $font-family;

      &:focus {
        border-radius: 0;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    overflow: hidden;
    overflow-y: scroll;
    max-height: 150px;
    li {
      list-style: none;
      background: $tetriary;
      padding: $spacing-small;
      border-bottom: 2px solid $primary;

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

@media screen and (min-width: 480px) {
  .window {
    ul {
      max-height: 250px;
      &::-webkit-scrollbar-track {
        background-color: $tetriary;
      }

      &::-webkit-scrollbar {
        width: 12px;
        background-color: $tetriary;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $primary;
      }
    }
    .chatbox {
      grid-template-columns: 50px 1fr auto;
    }
  }
}
</style>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
