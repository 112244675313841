<template>
  <div>
    <quiz
      v-if="inProgress"
      @fetchQuestion="fetchQuestion"
      @answerSelected="clientAnswered"
      :question="room.question"
      :selected="selected"
      :questionsAnswered="questionsAnswered"
      :answered="allAnswered"
    />
    <div v-else>
      <base-navbar :backIcon="true" />
      <base-room @joinedRoom="setRoom" @startGame="inProgress = true" />
      <base-chat-box
        v-if="room"
        :class="$style.chatbox"
        :messages="messages"
        @sendMessage="send"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueSocketIOExt from 'vue-socket.io-extended';
import { io } from 'socket.io-client';
import BaseNavbar from '@/components/BaseNavbar';
import BaseRoom from '@/components/BaseRoom';
import BaseChatBox from '@/components/BaseChatBox';
import Quiz from '@/components/Quiz';

const socket = io(process.env.VUE_APP_SOCKET_SERVER);

Vue.use(VueSocketIOExt, socket);

export default {
  name: 'Multiplayer',
  components: {
    BaseNavbar,
    BaseRoom,
    BaseChatBox,
    Quiz,
  },
  data() {
    return {
      room: null,
      messages: [],
      inProgress: false,
      allAnswered: true,
      selected: null,
      showInformation: true,
      questionsAnswered: {
        correct: 0,
        wrong: 0,
        count: 0,
      },
      index: null,
      answer: null,
      processing: false,
    };
  },
  sockets: {
    message(message) {
      this.messages.push(message);
      Vue.nextTick(() => {
        let ul = document.getElementById('chat-list');
        ul.scrollTop = ul.scrollHeight;
      });
    },
    roomStatus(room) {
      this.room = room;
    },
    allAnswered() {
      if (this.processing) {
        return;
      }
      console.log(this.questionsAnswered);
      this.processing = true;
      this.allAnswered = true;
      if (this.questionsAnswered.count <= 9) {
        if (this.answer.correct) {
          this.questionsAnswered.correct++;
        } else {
          this.questionsAnswered.wrong++;
        }
        setTimeout(() => {
          this.fetchQuestion();
          this.questionsAnswered.count++;
          this.selected = null;
          this.processing = false;
        }, 3000);
      } else {
        this.calculatePoints();
      }
    },
  },
  methods: {
    send(value) {
      this.$socket.client.emit('message', value, this.room);
    },
    setRoom(room) {
      this.room = room;
    },
    fetchQuestion() {
      if (this.allAnswered) {
        this.$socket.client.emit('fetchQuestion', this.room);
        this.allAnswered = false;
      }
    },
    clientAnswered(answer, index) {
      if (this.processing) {
        return;
      }
      this.index = index;
      this.selected = index;
      this.answer = answer;
      this.$socket.client.emit(
        'clientAnswered',
        this.room,
        this.$store.state.user.id
      );
    },
  },
};
</script>

<style lang="scss" module>
.chatbox {
  bottom: 0;
  position: absolute;
  width: 100%;
}
</style>
